import "../../../assets/css/custom.css";
import React, { useState, useEffect } from "react";
import { Typography, useMediaQuery, makeStyles } from "@material-ui/core";
import {
  Alert as BootstrapAlert,
  Modal,
  Button as Button1,
} from "react-bootstrap";
import { scroller } from "react-scroll";
import { NavBar } from "../navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Route } from "react-router-dom";
import { setLoading } from "../../../redux/publicReducer/actions";
import axios from "axios";
import { MenuNavigation } from "../components/MenuNavigation";
import BlankSpaceTen from "../components/BlankSpaceTen";
import { Footer } from "../components/Footer";
import { OrderSlider } from "../components/OrderSlider";
import { Grid } from "@mui/material";
import { removeExpiredItems } from "../../../redux/cartReducer/actions";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "wrap",
    height: "auto",
    gridTemplateColumns: "fit-content(300px) fit-content(300px) 1fr",
    transform: "translateZ(0)",
    padding: "0rem 10px",
    backgroundColor: "white",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  dialogTitleBar: {
    margin: 0,
    padding: theme.spacing(2),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const RestaurantHome = (props) => {
  const { state } = useLocation();
  const history = useHistory();
  const [menuData, setMenuData] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const { search } = useSelector((state) => state.public);

  const [updateTime, setUpdateTime] = useState("");
  const [chosenCategory, setChosenCategory] = useState("");

  const [show, setShow] = useState(true);
  const buttonClose = () => setShow(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categories, setCategories] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");

  const dispatch = useDispatch();

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const getMenus = async () => {
      try {
        const { data } = await axios.get(
          `https://ecommv2.servingintel.com/items/menu/${serverId}/${siteId}`
        );

        // To Be Deleted once the image added to the response API

        setMenuData(data);
      } catch (error) { }
    };

    getMenus();
  }, []);

  useEffect(() => {
    dispatch(removeExpiredItems());
    const intervalId = setInterval(() => {
      dispatch(removeExpiredItems());
    }, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (state?.category && categories.length) {
      setChosenCategory(state.category.DepartmentID);
      scroller.scrollTo(state.category.DepartmentID, {
        duration: 1500,
        delay: 300,
        smooth: true,
        offset: -110,
      });
    }
  }, [categories]);

  useEffect(() => {
    if (search && search !== "") {
      categories.map((category, i) => {
        const element = document.getElementById(category.DepartmentID);
        if (element) {
          const isImg = document
            .getElementById(category.DepartmentID)
            .getElementsByTagName("img");
          if (isImg) {
            if (isImg.length < 1) {
              document.getElementById(category.DepartmentID).style.display =
                "none";
            } else {
              document.getElementById(category.DepartmentID).style.display =
                "block";
            }
          }
        }
      });
    }
    if (!search || search == "") {
      categories.map((category, i) => {
        document.getElementById(category.DepartmentID).style.display = "";
      });

      scroller.scrollTo(chosenCategory, {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: -110,
      });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoading(true));
    const url =
      "https://ecommv2.servingintel.com/items/all/" +
      siteId +
      "?server_id=" +
      serverId;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (availableProducts !== response.data) {
          setAvailableProducts(response.data);
        }
      })
      .catch((error) => { });
    dispatch(setLoading(false));
  }, [updateTime]);

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/categories/" +
      siteId +
      "?server_id=" +
      serverId;
    //const url = "/servingintel_api/public/items/categories";
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => { });
  }, [updateTime]);

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  //temporarily disabled
  // useEffect(() => {
  //   var verifyOrderProcess = new EventSource(
  //     "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/" +
  //       siteId +
  //       "/" +
  //       getCookie("orderId") +
  //       "?server_id=" +
  //       serverId
  //   );
  //   verifyOrderProcess.onmessage = function (event) {
  //     if (event.data === 1) {
  //       verifyOrderProcess.close();
  //       setCookie("orderId", "");
  //     }
  //   };

  //   var source2 = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
  //   );
  //   source2.onmessage = function (event) {
  //     var u_items = JSON.parse(event.data);
  //     //console.log(u_items[0].utime);
  //     if (u_items[0].time != "") {
  //       if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
  //         setUpdateTime(u_items[0].time);
  //         sessionStorage.setItem("update_date_time", u_items[0].time);
  //       }
  //     }
  //   };
  // }, []);

  return (
    <React.Fragment>
      <div className="OrderNow">
        {siteId === 89 ? (
          <Modal show={show} onHide={buttonClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                WELCOME TO AJ SLIDERS!
                <br />
                <br />
                WHICH LOCATION WOULD YOU LIKE TO PICK-UP YOUR FOOD?
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <div
                className="d-grid gap-2"
                style={{ width: "100%", height: "100%" }}
              >
                <Button1 variant="danger" size="lg" onClick={buttonClose}>
                  AJ SLIDERS - ST.CHARLES, IL
                </Button1>
                <Button1
                  variant="danger"
                  size="lg"
                  onClick={(event) =>
                    (window.location.href = "https://ajshangout.com/menu")
                  }
                >
                  AJ'S HANGOUT - SOUTH ELGIN, IL
                </Button1>
              </div>
            </Modal.Footer>
          </Modal>
        ) : null}
        <MenuNavigation />
        <OrderSlider />
        <NavBar />
        <BlankSpaceTen />
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            margin: "40px auto",
            padding: "16px",
          }}
        >
          <Grid
            container
            spacing={{
              xs: 2,
              md: 4,
            }}
            columns={{ xs: 2, sm: 4, lg: 16 }}
          >
            {menuData.map((menu, index) => (
              <Grid
                item
                xs={2}
                sm={2}
                lg={4}
                key={index}
                sx={{
                  cursor: "pointer",
                }}
                className="main-menus"
                onClick={() => {
                  history.push("/menu/" + menu.MenuName.replace(/ /g, "-"));
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                      transition: "all 1.5s",
                    }}
                    src={
                      siteMedSrc +
                      menu.MenuName.replace(/ /g, "-")
                        .replace(",", "")
                        .toLowerCase() +
                      ".png"
                    }
                    alt={menu.MenuName}
                    onError={(error) => (error.target.src = siteMedSrc + "empty-img.png")}
                  />
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                  }}
                >
                  <Typography
                    // variant="h4"
                    style={{ fontWeight: "500", lineHeight: 1.1, fontSize: "1.5rem", marginTop: "1rem" }}
                  >
                    {menu.MenuName}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
