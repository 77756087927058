import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import { useHistory, useLocation } from "react-router-dom";

const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function LogoutIcon() {
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const state = useLocation();
  const scrollToDown = () => {
    window.scrollBy(0, window.innerHeight);
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollbars.visible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <React.Fragment>
      {/* {isVisible && ( */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: "1",
          marginRight: 5.5,
          width: "90px",
          boxShadow: 0,
          top: "auto",
          bottom: 10,
          backgroundColor: "transparent",
          marginBottom: "15px",
        }}
        onClick={() => {
          history.push({
            pathname: "/",
            state,
          });
        }}
      >
        <Toolbar style={{ backgroundColor: "transparent" }}>
          <Box sx={{ flexGrow: 1 }} />
          {enableKiosk === "1" ? (
            <IconButton>
              <Fab
                color="primary"
                aria-hidden="true"
                sx={{
                  backgroundColor: buttonColor,
                  transform: "scale(1.45)",
                  "&:hover": {
                    backgroundColor: colorBackground,
                  },
                }}
              >
                <LogoutTwoToneIcon />
              </Fab>
            </IconButton>
          ) : (
            <IconButton>
              <Fab
                color="primary"
                aria-hidden="true"
                sx={{
                  backgroundColor: buttonColor,
                  "&:hover": {
                    backgroundColor: colorBackground,
                  },
                }}
              >
                <LogoutTwoToneIcon />
              </Fab>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {/* )} */}
    </React.Fragment>
  );
}
