import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useSelector } from "react-redux";

export const Theme = ({ children }) => {
  const { darkMode } = useSelector((state) => state.public);
  const theme = createTheme({
    palette: {
      type: darkMode ? "dark" : "light",
      primary: {
        main: !darkMode ? "#000000" : "#0366d6",
        contrastText: "#fff",
      },
      secondary: {
        main: "#dc004e",
        contrastText: "#fff",
      },
    },

    typography: {
      fontFamily: ['"Poppins"', "Helvetica", "Arial", "sans-serif"].join(","),
    },
  });
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default Theme;
