import React, { useState } from "react";
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import QrReader from "react-qr-scanner";
import { toast } from "react-toastify";

export const QRDialog = ({ open, setOpen }) => {
  const [menuLink, setMenuLink] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Scan Restaurant QR Code</DialogTitle>
      <DialogContent dividers>
        <div>
          <QrReader
            style={{ width: "100%", height: "80%" }}
            onScan={(data) => {
              if (data?.text) {
                setMenuLink(data.text);
              }
            }}
            onError={() => {
              toast.error("Feature not supported");
              setOpen(false);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {menuLink && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              window.location.href = menuLink;
            }}
          >
            See Menu
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
