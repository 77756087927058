import "../../../App.css";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { orange } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function KitchenStaffCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 767 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: orange[800] }} aria-label="kitchen-staff">
            KS
          </Avatar>
        }
        className="left"  
        title="Kitchen Staff"
      />
      <CardMedia
        component="img"
        width="100%"
        height="194"
        image="https://d1ralsognjng37.cloudfront.net/7b3e7372-05e9-494f-9a50-f639252baed5.jpeg"
        alt="Kitchen Staff"
        title="Kitchen Staff"
        loading="lazy"
      />
      <CardContent>
        <Typography variant="body1" color="text.primary">
Cook meats, fish, vegetables, soups and other hot food products as well as prepare and portion food products prior to cooking. Also perform other duties in the areas of food and final order preparation. May take orders from customers and serve guests at counters or tables.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button size="large" aria-label="large button" variant="contained">Apply Now</Button>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h5" paragraph left>Job Title: Cook / Prep</Typography>
          <Typography variant="h6" paragraph left>
            Reports to: Kitchen Manager
          </Typography>
          <Typography variant="body1" paragraph left>
            Job Summary: Cook meats, fish, vegetables, soups and other hot food products as well as prepare and portion food products prior to cooking. Also perform other duties in the areas of food and final order preparation. May take orders from customers and serve guests at counters or tables.
          </Typography>
          <Typography variant="h5" paragraph left>
            Activities & Responsibilities
          </Typography>
          <Typography variant="h6" paragraph left>
            Primary
          </Typography>
            <List>
                <ListItem>
                    Promote, work, and act in a manner consistent with the mission of AJ Sliders: To serve outstanding food and provide exceptional customer service by treating every transaction as the most important of the day. To treat our customers like friends and family members. To listen intently to our customers and provide them with great value and a satisfying experience each and every time. To provide an atmosphere where customers feel welcomed and appreciated, a place that is easy to recommend on social media and by word of mouth.
                </ListItem>
                <ListItem>
                    Maintain sanitation, health, and safety standards in work areas.
                </ListItem>
                <ListItem>
                    Clean food preparation areas, cooking surfaces, and utensils.
                </ListItem>
                <ListItem>
                    Verify that prepared food meets requirements for quality and quantity.
                </ListItem>
                <ListItem>
                    Cook and package batches of food, such as hamburgers and fried chicken, which are prepared to order or kept warm until sold.
                </ListItem>
                <ListItem>
                    Prepare specialty foods such as pizzas, fish and chips, sandwiches, and tacos, following specific methods that usually require short preparation time.
                </ListItem>
                <ListItem>
                    Operate large-volume cooking equipment such as grills, nitrogen ice cream machine, laser soda gun.
                </ListItem>
                <ListItem>
                    Read food order slips or receive verbal instructions as to food required by patron, and prepare and cook food according to instructions.
                </ListItem>
                <ListItem>
                    Measure ingredients required for specific food items being prepared.
                </ListItem>
                <ListItem>
                    Take food and drink orders and receive payment from customers.
                </ListItem>
                <ListItem>
                    Clean, stock, and restock workstations and display cases.
                </ListItem>
                <ListItem>
                    Cook the exact number of items ordered by each customer, working on several different orders simultaneously.
                </ListItem>
                <ListItem>
                    Wash, cut, and prepare foods designated for cooking.
                </ListItem>
                <ListItem>
                    Serve orders to customers at windows, counters, or tables.
                </ListItem>
                <ListItem>
                    Prepare and serve beverages such as coffee and craft soda drinks.
                </ListItem>
                <ListItem>
                    Pre-cook items such as bacon, to prepare them for later use.
                </ListItem>
                <ListItem>
                    Attend all scheduled employee meetings and offers suggestions for improvement.
                </ListItem>
                <ListItem>
                    Coordinate with and assist fellow employees to meet guests’ needs and support the operation of the restaurant.
                </ListItem>
                <ListItem>
                    Fill-in for fellow employees where needed to ensure guest service standards and efficient operations.
                </ListItem>
            </List>
          <Typography variant="h6" paragraph left>
            Secondary
          </Typography>
            <List>
                <ListItem>
                    Take guests’ orders and write ordered items on tickets, giving ticket stubs to customers when needed to identify filled orders.
                </ListItem>
                <ListItem>
                    Order items needed to replenish supplies.
                </ListItem>
                <ListItem>
                    Serve salads, vegetables, meat, breads, and cocktails, ladle soups and sauces, portion desserts, and fill beverage cups and glasses.
                </ListItem>
                <ListItem>
                    Set up dining areas for meals and clear them following meals.
                </ListItem>
                <ListItem>
                    Carve meat.
                </ListItem>
                <ListItem>
                    Deliver orders to kitchens and pick up and serve food when it is ready.
                </ListItem>
                <ListItem>
                    Add relishes and garnishes to food orders, according to instructions.
                </ListItem>
                <ListItem>
                    Arrange reservations for patrons of dining establishments.
                </ListItem>
            </List>
          <Typography variant="h5" paragraph left>
            Tools & Technology (examples in parentheses)
          </Typography>
            <List>
                <ListItem>
                    Credit card processing machines
                </ListItem>
                <ListItem>
                    Laser Soda Gun
                </ListItem>
                <ListItem>
                    Coffee machines
                </ListItem>
                <ListItem>
                    Ice dispensers
                </ListItem>
                <ListItem>
                    Ice-making machines
                </ListItem>
                <ListItem>
                    Intercom systems
                </ListItem>
                <ListItem>
                    Non-carbonated beverage dispenser
                </ListItem>
                <ListItem>
                    Personal computers
                </ListItem>
                <ListItem>
                    Pocket calculator
                </ListItem>
                <ListItem>
                    Point-of-sale terminals and worksta ons
                </ListItem>
                <ListItem>
                    Touch screen monitors
                </ListItem>
                <ListItem>
                    Data base user interface and query so ware
                </ListItem>
            </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}
