import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import MoreIcon from "@mui/icons-material/MoreVert";
import Link from "@mui/material/Link";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import "../../../assets/css/custom.css"

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{ color: "white" }}
    >
      <br></br>
      Made with <FavoriteTwoToneIcon
        sx={{ color: "#820813" }}
      /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img
          width="115px"
          height="100%"
          title="ServingIntel Logo"
          loading="lazy"
          src={siteMedSrc + "si-logo" + ".svg"}
          alt="ServingIntel Logo"
        />
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "320px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function BottomSILogo() {
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        sx={{
          boxShadow: 0,
          top: "auto",
          bottom: "50px",
          marginTop: "20px",
          backgroundColor: "transparent",
          zIndex: 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label=""
            sx={{ transform: "scale(1.5)", display: "none" }}
          >
            <ArrowBackTwoToneIcon />
          </IconButton>
          <StyledFab id="nohover" disableautofocus disableenforcefocus>
            <Copyright position="centered"
              sx={{
                top: "auto",
                bottom: 0,
                "&:hover": { backgroundColor: "none" },
                "&:focus": { backgroundColor: "none" },
                "&:active": { backgroundColor: "none" }
              }}
            />
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            href="/"
            aria-label=""
            sx={{ transform: "scale(1.5)", display: "none" }}
          >
            <LogoutTwoToneIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}