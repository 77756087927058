import React from "react";
import {
  Card,
  CardMedia,
  Typography,
  CardContent,
  Divider,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "95%",
    margin: "auto",
    transition: "0.3s",
    // border: "1px solid #000000",
  },
  media: {
    paddingTop: "56.25%",
  },
  [theme.breakpoints.down("sm")]: {
    media: {
      paddingTop: "40%",
    },
  },
  content: {
    textAlign: "left",
    padding: theme.spacing.unit * 3,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  subheading: {
    lineHeight: 1.8,
  },
}));

export const RestaurantCard = ({ name, description, image }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={0} square>
      <CardMedia className={classes.media} image={image} />
      <CardContent className={classes.content}>
        <Typography className={classes.heading} variant={"h6"} gutterBottom>
          {name}
        </Typography>
        <Typography className={"MuiTypography--subheading"} variant={"caption"}>
          {description}
        </Typography>
        <Divider className={classes.divider} light />
      </CardContent>
    </Card>
  );
};
