import "../../../App.css";
import { Container, Row, Col, h5, p, h2, div } from "react-bootstrap";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import React, { useEffect } from "react";
import { MenuNavigation } from '../components/MenuNavigation';
import BlankSpace from "../components/BlankSpace";
import {Footer} from '../components/Footer';
import RoomsContent from '../components/RoomsContent';
import {MeetingRoomSlider} from "../components/MeetingRoomSlider";
import { NavBar, LowerNav } from "../navigation/NavBar";
import ReactGA from "react-ga";

export const MeetingRooms = () => {

  useEffect(() => {
    document.title = "Meeting Rooms";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

return (
      
<div className="OrderNow">
    <MenuNavigation/>
    <MeetingRoomSlider/>
    <RoomsContent />
    <BlankSpace/>
    <Footer/>
</div>

  );
};
