import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function ScrollToDown() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToDown = () => {
    window.scrollBy(0, window.innerHeight);
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollbars.visible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <React.Fragment>
      {isVisible && (
        <AppBar
          position="fixed"
          sx={{
            width: "90px",
            boxShadow: 0,
            top: "auto",
            bottom: 120,
            backgroundColor: "transparent",
            marginBottom: "50px",
          }}
          onClick={scrollToDown}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton>
              <Fab
                color="primary"
                aria-hidden="true"
                sx={{ backgroundColor: "#00296F" }}
              >
                <ArrowDownwardIcon />
              </Fab>
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
    </React.Fragment>
  );
}
