import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export const TimeComponent = ({ onChange, value, type = "DELIVERY" }) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let startDateUtc = moment(startDate, "hh:mm a");
    let endDate = new Date();
    endDate.setHours(12, 0, 0, 0);
    let endDateUtc = moment(endDate, "hh:mm a");
    const diff = endDateUtc.diff(startDateUtc) / 3600000;
    const timeLength = diff * 4;
    let spots = [];

    for (let i = 0; i < timeLength; i++) {
      spots.push({
        hour: moment(startDate)
          .add(30 * i, "minutes")
          .format("hh:mm a"),
      });
    }
    setTimeSlots(spots);
  }, []);
  return (
    <Paper elevation={0}>
      <Typography color={!value ? "secondary" : "primary"}>
        {value?.hour || `Select time for ${type === "DELIVERY" && "delivery" || "pickup"}`}
      </Typography>
      <div
        style={{ display: "flex", alignItems: "center", margin: "1rem 0rem" }}
      >
        <IconButton
          onClick={() => setCount(count > 0 ? count - 1 : 0)}
          disabled={!count}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={() => setCount(count + 1)} disabled={count === 3}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <Grid container spacing={1}>
        {timeSlots.map((slot, index) => {
          console.log(count * 16, index);
          if (index <= (count + 1) * 14 && index >= count * 14) {
            return (
              <Grid
                item
                xs={4}
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant={value === slot ? "contained" : "outlined"}
                  color="primary"
                  style={{ borderRadius: "15px" }}
                  onClick={() => onChange(slot)}
                  fullWidth
                >
                  {slot.hour}
                </Button>
              </Grid>
            );
          }
        })}
      </Grid>
    </Paper>
  );
};
