import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { IconButton } from "@material-ui/core";

export const NotificationBell = () => {
  const { user } = useSelector((state) => state.public);

  const [notifs, setNotifs] = useState([]);

  // useEffect(() => {
  //   const unsub = orderReference.onSnapshot((snapshot) => {
  //     setNotifs(
  //       snapshot.docs.map((doc) => {
  //         return { ...doc.data(), id: doc.id };
  //       })
  //     );
  //   });

  //   return unsub;
  // }, []);

  if (!user && !user.userType === "CUSTOMER") {
    return null;
  }
  return (
    <IconButton>
      <NotificationsIcon style={{ color: "yellow" }} fontSize="large" />
    </IconButton>
  );
};
