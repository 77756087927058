const config = {

    // Landing Page
    landingcontent: {
        title: "AJ's Keto Factory and Deli & Keto Retail Shop",
        subtitle: "Deli & Keto Retail Shop offering wheat-free, gluten-free, sugar-free, and soy-free food.",
        description: "AJ's Keto Factory: Deli & Keto Retail Shop offering wheat-free, gluten-free, sugar-free, and soy-free food.",
        keywords: "Homepage, Keto, Gluten-free, Sugar-free, Wheat-free, Low-carb, Healthy lifestyle, Ambiguous restaurant, Kingdom Business, Business as a mission, Quality food, Extraordinary taste, Convenience, Cravings, Piping-hot, Fridge stock-up, Satisfy, Low-carb lifestyle, Community, Jesus Christ, Love, Live, Promote, Healthy living, St. Charles, Geneva, South Elgin, Elgin, Near me, Visit us today, Keto boost, Stock up, Delivery, Fresh, Natural ingredients, Delicious, Purity, Wholesome, Nourishing, Savory, Tasty, Flavorful, Nutritious, Guilt-free, Family-friendly, Diverse menu, Mouthwatering, Tempting, Delightful, Exclusive recipes, Innovative, Culinary excellence, Craftsmanship, Signature dishes, Unforgettable, Satisfaction, Filling, Energizing, Divine flavors.",
    },

    // About Us Page
    aboutcontent: {
        title: "Great way to start the day",
        subtitle: "Purpose: to love and serve to others through excellence in every encounter.",
        description: "AJ's Keto Factory is an ambiguous restaurant venture focused on providing great-tasting food that is low-carb, gluten-free, wheat-free, sugar-free, and soy-free while serving the community as a 'business as a mission.' Sharing the love of Jesus Christ.",
        keywords: "Purpose, love and serve, excellence, start the day, Deli, Grocery Store, great-tasting food, low-carb, gluten-free, wheat-free, sugar-free, soy-free, business as a mission, Jesus Christ, Family-owned, Discounts, gluten-free friendly, Grab-and-go, Catering Services, Why, Mission, Kingdom Business, Core Values, Loving All People, Impacting the World, Creating Superheroes, Exploding the Box, Keto, St. Charles, Geneva, South Elgin, near me, Ambitious Deli, Keto Retail Shop, preservative-free, fresh daily, satisfying, Holy Spirit, leaders, community, physical and spiritual needs, God's purpose, intentional love, needs of others, impacting, serve, sacrifices, Fox Valley Christian Action, Lazarus House, Hope Coffee, superheroes, capabilities, collaboration, healthy drinks, incredible food items, toxic sugar-free, gluten-free, wheat-free",
        header: "AJ's Keto Factory Why - Our Mission",
        message: "We are a team who seeks direction from the Holy Spirit, led by leaders dedicated to using our time, talent, and money to meet the community's physical and spiritual needs to advance God's purpose.",
    },
    aboutbusiness: {
        paragraph1: "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
        paragraph2: "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
    },
    littleaboutus: {
        paragraph1: "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
        paragraph2: "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
        paragraph3: "Savor the essence of guilt-free indulgence at our Keto haven! Delight in a symphony of flavors, from gluten-free and sugar-free to soy-free and wheat-free options. Whether you opt for dine-in luxury or the convenience of curbside pickup and no-contact delivery, our culinary journey awaits. Nestled in St. Charles, Geneva, and South Elgin, our oasis beckons with delectable keto creations made from fresh, natural ingredients. Dive into our gourmet, carb-free offerings, featuring tantalizing keto bakery treats and a medley of satisfying options that redefine the art of healthy dining. Immerse yourself in the essence of the keto lifestyle with our nutrient-rich, high-quality fare, setting the standard for excellence in St. Charles, Geneva, and South Elgin.",
    },

    // Contact Page
    contactcontent: {
        title: "Give us a call at +1 224-538-6669 or come by for a visit! Order delivery or pickup from AJ's Keto Factory in St. Charles!",
        description: "Indulge in a flavorful journey at our Keto haven! From gluten-free and sugar-free delights to soy-free and wheat-free options, our menu is a celebration of guilt-free dining. Enjoy dine-in or choose curbside pickup and no-contact delivery for a seamless experience. Located in St. Charles, Geneva, and South Elgin, we're your go-to spot for delicious keto meals crafted with fresh, natural ingredients. Our gourmet, carb-free offerings, including keto bakery treats and satisfying keto options, redefine healthy dining. Embrace the keto lifestyle with nutrient-rich, high-quality food, and experience the best in St. Charles dining, Geneva restaurant excellence, and South Elgin eatery comfort. Wholesome, flavorful, and satisfying – welcome to a world of quality keto dining!",
        keywords: "Keto, Gluten-free, Sugar-free, Wheat-free, Soy-free, Dine-in, Curbside pickup, No-contact delivery, St. Charles, Geneva, South Elgin, Near me, Low-carb, Healthy eating, Natural ingredients, Delicious keto meals, Keto bakery, Keto desserts, Keto-friendly, Carb-free, Keto rolls, Sugar-free treats, Keto-friendly restaurant, Fresh ingredients, All-natural, High-quality food, Gourmet keto, Keto options, Healthy dining, Local ingredients, Low-carb bakery, Wheat-free bakery, Gluten-free bakery, No-contact food delivery, Curbside food pickup, Keto meal delivery, Keto meal plans, Keto catering, Keto-friendly menu, St. Charles dining, Geneva restaurant, South Elgin eatery, Best keto food, Tasty keto, Keto comfort food, Low-carb dining, Nutrient-rich meals, Flavorful keto, Satisfying keto, Keto meal prep, Wholesome keto, Keto lifestyle, Guilt-free dining, Quality keto food",
        message: "Welcome to a world where wholesome, flavorful, and satisfying keto dining takes center stage!"
    },

    // Photo Gallery Page
    gallerycontent: {
        title: "Your taste buds deserve the extraordinary – savor the delight at our ambitious destination",
        description: "Embark on a visual feast at our ambitious deli and grocery store! Explore a gallery of delicious culinary delights, showcasing our commitment to low-carb, gluten-free, wheat-free, sugar-free, and soy-free options. Our menu highlights not only great-tasting food but also healthy choices that are keto-friendly and of the highest quality. Immerse yourself in a visual experience through our photo gallery, capturing the essence of our wholesome options. Join us for an exploration of delightful flavors, where every image tells a story of delicious, ambitious, and wholesome culinary excellence.",
        keywords: "Explore, Photo Gallery, Deli, Grocery Store, Low-carb, Gluten-free, Wheat-free, Sugar-free, Soy-free, Ambitious, Great-tasting food, Healthy choices, Keto-friendly, Quality products, Delicious, Food images, Visual experience, Menu highlights, Culinary delights, Wholesome options.",
        message: "Step into our culinary haven, where ambition meets flavor! Explore our gallery of delicious delights at the ambitious deli and grocery store.",
    },

    // Menu Page
    ordercontent: {
        title: "Elevate your keto lifestyle with AJ's – where every bite is a celebration of flavor and wellness",
        description: "Satisfy your cravings guilt-free with AJ's Keto, your premier destination for delicious and wholesome keto delights! Indulge in our enticing array of keto meals, from rolls to sandwiches, sweets to drinks, all meticulously crafted for your low-carb lifestyle. Discover a menu bursting with guilt-free, gluten-free, sugar-free, and wheat-free options, proudly serving St. Charles, Geneva, and South Elgin",
        keywords: "Keto Meals, Keto Delights, Low-Carb Food, Guilt-Free Food, Gluten-Free Delights, Sugar-Free Treats, Wheat-Free Menu, Keto Sweets, Keto Rolls, Sandwiches, Keto Drinks, Sweets Desserts, Keto Cakes, Cinnamon Treats, Zevia Drinks, Keto Soup, St. Charles Keto, Geneva Keto, South Elgin Keto, Near Me Keto, Keto Delivery, Convenient Keto, Healthy Food, Delicious Keto, Fresh Ingredients, All-Natural Food, Keto Bakery, Keto Snacks, Low-Carb Bakery, Sugar-Free Bakery, Gluten-Free Bakery, Wheat-Free Bakery, Keto Factory, AJ's Keto, Keto Market, Keto Choices, Keto Lifestyle, Keto Diet, Keto Catering, Keto Options, Keto Takeout, Keto Restaurant, Keto Cafe, Keto Store, Keto Shop, Keto Online Orders, Keto Food Near Me, Keto Treats, Keto Sweets Near Me, Low-Carb Sweets, Low-Carb Meals, Sugar-Free Delights, Gluten-Free Meals.",
        message: "Enjoy the convenience of keto delivery and takeout, or visit our keto cafe for fresh and healthy choices. Elevate your keto lifestyle with AJ's – where every bite is a celebration of flavor and wellness!",
    },

    // Eloyalty Page
    eloyaltycontent: {
        title: "Join our eLoyalty Club for exclusive member benefits, specials, and events, earning AJ Bucks for loyalty rewards.",
        description: "Discover a world of delicious possibilities at AJ's Keto Factory – your go-to haven for all things keto! Indulge in our gluten-free, sugar-free, and wheat-free options crafted for a healthy and satisfying low-carb lifestyle. Dive into the goodness of our homemade, high-quality keto bakery delights, featuring irresistible sweets, desserts, rolls, and treats. Located in St. Charles, Geneva, and South Elgin, we offer local delivery and convenient pickup for your keto cravings.",
        keywords: "Keto, Gluten-free, Sugar-free, Wheat-free, Low-carb, Healthy eating, All-natural, Delicious keto, Keto bakery, Keto sweets, Keto desserts, Keto meals, Keto rolls, Keto treats, Keto food, Keto products, Keto-friendly, St. Charles, Geneva, South Elgin, Nearby, Local, Delivery, Pickup, Keto delivery, Keto pickup, AJ's Keto Factory, eLoyalty Club, AJ's Club, Member benefits, Specials, Events, AJ Bucks, Loyalty rewards, Activate account, Member-only, Keto market, Sugar-free options, Wheat-free choices, Gluten-free bakery, Natural ingredients, Nutritious, Tasty, Gourmet, Homemade, High-quality, Fresh, Local ingredients, Health-conscious, Weight management, Low-carb lifestyle, Satisfying keto, Best keto foods.",
        message: "Activate your account now and explore our gourmet, health-conscious menu, packed with natural ingredients and nutritious choices.",
    },
};

export default config;