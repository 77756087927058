import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  useMediaQuery,
  Paper,
  Typography,
  Grid,
} from "@material-ui/core";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import BackspaceIcon from "@material-ui/icons/Backspace";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";

export const CartList = ({ orders }) => {
  const { cartItems } = useSelector((state) => state.cart);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [chosen, setChosen] = useState(null);
  const [totalItemsPrice, setTotalItemsPrice] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += item.price * item.quantity;
      item?.modifiers?.forEach((modifier) => {
        totalPrice += modifier.price * item.quantity;
      });
    });
    setTotalItemsPrice(totalPrice);
    dispatch({ type: "SET_TOTAL_PRICE", payload: totalPrice });
  }, [cartItems]);

  return (
    <>
      {(!isMobile && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit price</TableCell>
              <TableCell>Add-ons</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {cartItems.map((item) => {
              let addOnPrice = 0;
              return (
                <TableRow style={{ maxWidth: isMobile ? "100%" : "" }}>
                  <TableCell>
                    <img
                      src={item.image}
                      style={{ maxWidth: isMobile ? "40px" : "150px" }}
                    />
                  </TableCell>
                  <TableCell>
                    {item.productName}
                    {item?.modifiers?.map((modifier) => {
                      return (
                        <p
                          style={{
                            fontSize: "0.7rem",
                            color: "gray",
                          }}
                        >
                          {modifier.price
                            ? `${modifier.name} + $ ${numberWithCommas(
                                modifier.price
                              )}`
                            : `${modifier.name}`}
                        </p>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        if (item.quantity > 1) {
                          const newCartItems = cartItems.map((cartItem) => {
                            if (cartItem === item) {
                              return { ...item, quantity: item.quantity - 1 };
                            }

                            return cartItem;
                          });

                          dispatch({ type: "SET_CART", payload: newCartItems });
                        } else {
                          const filterCartItems = cartItems
                            .map((i) => i)
                            .filter((j) => j !== item);
                          dispatch({
                            type: "SET_CART",
                            payload: filterCartItems,
                          });
                        }
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    {item.quantity}
                    <IconButton
                      onClick={() => {
                        const newCartItems = cartItems.map((i) => {
                          if (i === item) {
                            return { ...i, quantity: i.quantity + 1 };
                          }
                          return i;
                        });

                        dispatch({ type: "SET_CART", payload: newCartItems });
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>$ {numberWithCommas(item.price)}</TableCell>
                  <TableCell>
                    {(() => {
                      item?.modifiers?.forEach((modifier) => {
                        addOnPrice += modifier.price * item.quantity;
                      });

                      return `$ ${numberWithCommas(addOnPrice)}`;
                    })()}
                  </TableCell>
                  <TableCell>
                    ${" "}
                    {numberWithCommas(item.price * item.quantity + addOnPrice)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        let deleteCartItems = cartItems
                          .map((cart) => cart)
                          .filter((i) => i !== item);
                        dispatch({
                          type: "SET_CART",
                          payload: deleteCartItems,
                        });
                      }}
                    >
                      <BackspaceIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>$ {numberWithCommas(totalItemsPrice)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )) || (
        <Grid container spacing={1}>
          {cartItems.map((item, index) => {
            let addOnPrice = 0;

            return (
              <Fragment key={index}>
                <Grid
                  item
                  xs={chosen === index ? 10 : 12}
                  onClick={() => {
                    if (chosen != index) {
                      setChosen(index);
                    } else {
                      setChosen(null);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      //   justifyContent: "space-between",
                      padding: "1rem",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "15px",
                      marginLeft: chosen === index && "-100px",
                    }}
                  >
                    <div>
                      <img src={item.image} style={{ maxWidth: "75px" }} />
                    </div>
                    <div style={{ margin: "0px 12px 0px 6px" }}>
                      <Typography
                        style={{ fontWeight: 800 }}
                        variant="subtitle2"
                      >
                        {item.productName}
                      </Typography>
                      <Typography variant="caption" style={{ color: "gray" }}>
                        {item?.description}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 800 }}
                      >
                        $ {numberWithCommas(item.price)}
                      </Typography>
                      {item?.modifiers?.map((modifier) => {
                        return (
                          <Typography
                            style={{
                              fontSize: "0.7rem",
                              color: "gray",
                            }}
                          >
                            {modifier.price
                              ? `${modifier.name} + $ ${numberWithCommas(
                                  modifier.price
                                )}`
                              : `${modifier.name}`}
                          </Typography>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.quantity > 1) {
                            const newCartItems = cartItems.map((cartItem) => {
                              if (cartItem === item) {
                                return { ...item, quantity: item.quantity - 1 };
                              }

                              return cartItem;
                            });

                            dispatch({
                              type: "SET_CART",
                              payload: newCartItems,
                            });
                          } else {
                            const filterCartItems = cartItems
                              .map((i) => i)
                              .filter((j) => j !== item);
                            dispatch({
                              type: "SET_CART",
                              payload: filterCartItems,
                            });
                          }
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      {item.quantity}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          const newCartItems = cartItems.map((i) => {
                            if (i === item) {
                              return { ...i, quantity: i.quantity + 1 };
                            }
                            return i;
                          });

                          dispatch({ type: "SET_CART", payload: newCartItems });
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
                {chosen === index && (
                  <Grid
                    item
                    xs={2}
                    style={{
                      margin: "1rem 0rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1rem",
                      borderRadius: "15px",
                      backgroundColor: "#FFCCCB",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let deleteCartItems = cartItems
                        .map((cart) => cart)
                        .filter((i) => i !== item);
                      dispatch({
                        type: "SET_CART",
                        payload: deleteCartItems,
                      });
                    }}
                  >
                    <IconButton>
                      <DeleteIcon fontSize="default" />
                    </IconButton>
                  </Grid>
                )}
              </Fragment>
            );
          })}
        </Grid>
      )}
    </>
  );
};
