import React from "react";

import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Container,
  useMediaQuery,
  InputBase,
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const sliders = [
  {
    image:
      "https://api.asm.skype.com/v1/objects/0-eaua-d5-8f44062db53e68f2403f0190d3a91f05/views/imgpsh_fullsize_anim",
    name: "Testing Product",
    price: "$1.75",
  },
  {
    image:
      "https://api.asm.skype.com/v1/objects/0-eaua-d3-2177c5cec8b095800ffd3a3d12c2d80b/views/imgpsh_fullsize_anim",
    name: "Testing Product 1",
    price: "$1.75",
  },
  {
    image:
      "https://api.asm.skype.com/v1/objects/0-eaua-d1-0c9f6805abbb64f0767283ebbee3df11/views/imgpsh_fullsize_anim",
    name: "Testing Product 2",
    price: "$1.75",
  },
  {
    image:
      "https://api.asm.skype.com/v1/objects/0-eaua-d5-8f44062db53e68f2403f0190d3a91f05/views/imgpsh_fullsize_anim",
    name: "Testing Product",
    price: "$1.75",
  },
  {
    image:
      "https://api.asm.skype.com/v1/objects/0-eaua-d3-2177c5cec8b095800ffd3a3d12c2d80b/views/imgpsh_fullsize_anim",
    name: "Testing Product 1",
    price: "$1.75",
  },
  {
    image:
      "https://api.asm.skype.com/v1/objects/0-eaua-d1-0c9f6805abbb64f0767283ebbee3df11/views/imgpsh_fullsize_anim",
    name: "Testing Product 2",
    price: "$1.75",
  },
];

export const NewLayout = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          height: "100vh",
          padding: "2rem",
          paddingLeft: (isMobile && "4rem") || "2rem",
        }}
      >
        <Container>
          <Paper elevation={0} style={{ minHeight: "20vh" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <img
                  src={
                    "https://api.asm.skype.com/v1/objects/0-eaua-d5-c7721ad96cd9d67fc8b735c8222dd46a/views/imgpsh_fullsize_anim"
                  }
                  style={{ maxWidth: "75px" }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton>
                  <SearchIcon fontSize="default" />
                </IconButton>
                <IconButton>
                  <MenuIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          </Paper>
          <div style={{ margin: "2rem 0rem", maxWidth: "500px" }}>
            <Typography variant="caption">Taste's great, No Soy</Typography>
            <Typography style={{ fontWeight: 800, fontSize: "2.5rem" }}>
              We make delicious Sliders
            </Typography>
            <Typography variant="caption" style={{ color: "#111111" }}>
              AJ Slider's purpose is to love and serve others through excellence
              in every encounter, happiness in every moment and that inspire and
              empower our production staff to expand as a business whether
              that's to be a worldwide business or just to open up a few more
              restaurants around the country.
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              minHeight: "20vh",
            }}
          >
            <Typography variant="caption">FACEBOOK</Typography>
            <Typography variant="caption" style={{ margin: "0rem 2rem" }}>
              TWITTER
            </Typography>
            <Typography variant="caption">INSTAGRAM</Typography>
          </div>
        </Container>
      </Grid>
      <Grid item xs={12} md={6} style={{ height: "100vh" }}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} style={{ height: "50%", maxHeight: "50%" }}>
            <Carousel
              swipeable
              autoPlay
              emulateTouch
              infiniteLoop
              renderThumbs={() => null}
              renderIndicator={() => null}
            >
              {sliders.map((slider) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "50vh",
                      minHeight: "50vh",
                    }}
                  >
                    <img
                      src={slider.image}
                      style={{ maxWidth: "100%", height: "100%" }}
                    />
                  </div>
                );
              })}
            </Carousel>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "50vh",
                minHeight: "50vh",
              }}
            >
              <img
                src="https://api.asm.skype.com/v1/objects/0-eaua-d1-d02028f8c6b6768b838112d58199f70e/views/imgpsh_fullsize_anim"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f6bb41",
                minHeight: "50vh",
                flexDirection: "column",
              }}
            >
              <Typography style={{ color: "white" }}>
                Taste's Great, Glutten Free
              </Typography>
              <br />
              <Typography
                variant="h4"
                style={{ color: "black", fontWeight: 800, textAlign: "center" }}
              >
                See Our <span style={{ color: "red" }}>Delicious</span> Food
                Menu
              </Typography>
              <br />
              <Typography
                style={{ color: "red", fontWeight: 800 }}
                variant="h6"
              >
                ORDER NOW!
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CategoryModel = ({ product }) => {
  return (
    <Paper
      style={{
        borderRadius: "15px",
        minHeight: "30vh",
        minWidth: "200px",
        width: "200px",
        backgroundImage: `url(${product.image})`,
        margin: "0rem 6px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        paddingBottom: "2rem",
        paddingLeft: "2rem",
      }}
    >
      <Typography
        style={{
          color: "white",
          textShadow: "2px 2px 6px black",
          fontWeight: 800,
        }}
      >
        {product.name}
      </Typography>
    </Paper>
  );
};

export const NewCategory = () => {
  const categoriesArr = [
    {
      image:
        "https://images.unsplash.com/photo-1533910534207-90f31029a78e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80",
      name: "Best Seller",
    },
    {
      image:
        "https://images.unsplash.com/photo-1514517521153-1be72277b32f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80",
      name: "New this week",
    },
  ];
  return (
    <Container>
      <br />
      <Typography variant="subtitle1" style={{ color: "gray" }}>
        {" "}
        Hi David,{" "}
      </Typography>
      <Typography variant="h5">What would you like to order today?</Typography>
      <Paper
        style={{
          padding: "0rem 1rem",
          margin: "1rem 0rem",
          display: "flex",
          alignItems: "center",
        }}
        elevation={1}
      >
        <InputBase
          placeholder="or type what you are looking"
          style={{ width: "100%" }}
        />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Paper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          padding: "2rem 0rem",
        }}
      >
        {sliders.map((slider) => {
          return <CategoryModel product={slider} />;
        })}
      </div>
      <div>
        <Typography variant="h6" style={{ fontWeight: 800 }}>
          Explore from categories
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflowX: "auto",
            padding: "2rem 0rem",
          }}
        >
          {categoriesArr.map((category) => {
            return <CategoryModel product={category} />;
          })}
        </div>
      </div>
    </Container>
  );
};
