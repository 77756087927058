export const numberWithCommas = (x = 0, fixed = 2) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(toNumberString(x))
    .toFixed(fixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toNumberString = (val = 0) => {
  // return val.toString().replace(/,/g, '');
  return val.toString().replace(/[^\d.-]*/g, "");
};
