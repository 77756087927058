export const SET_USER = "SET_USER";
export const SET_MAIN_SEARCH = "SET_MAIN_SEARCH";
export const SET_LOADING = "SET_LOADING";
export const SET_CURRENT_RESTAURANT = "SET_CURRENT_RESTAURANT";
export const SET_BELL_COUNT = "SET_BELL_COUNT";
export const SET_SEARCH = "SET_SEARCH";
export const SET_RESTAURANT_PRODUCTS = "SET_RESTAURANT_PRODUCTS";
export const SET_VOICE_PHONE_NUMBER = "SET_VOICE_PHONE_NUMBER";
export const SET_VOICE_DELIVERY = "SET_VOICE_DELIVERY";
export const SET_SHOULD_RELOAD = "SET_SHOULD_RELOAD";
export const SET_SELECTED_SERVICE_OPTION = "SET_SELECTED_SERVICE_OPTION";
export const SET_ORDER_PROCESSING_DATE = "SET_ORDER_PROCESSING_DATE";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
