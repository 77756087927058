import "../../../App.css";
import { Container, Row, Col, h5, p, h2, div } from "react-bootstrap";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from '../components/MenuNavigation';
import { Restaurant } from '../restaurant/Restaurant';
import BlankSpace from "../components/BlankSpace";
import {Footer} from '../components/Footer';
import {OrderSlider} from "../components/OrderSlider";
import { NavBar, LowerNav } from "../navigation/NavBar";


export const OrderNow = () => {
  return (
      
<div className="OrderNow">
    <MenuNavigation/>
    <OrderSlider/>
    <BlankSpace/>
    <Restaurant/>
    <Footer/>
</div>

  );
};
