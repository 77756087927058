import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RewardsPurchases() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return [
    // <Card sx={{ maxWidth: 800 }} align="center" style={{ margin: "30px auto" }}>
    //   <CardContent>
    //     <Typography variant="h5" color="text.secondary">
    //       <span
    //         style={{ float: "left", fontWeight: "600", fontSize: "24px" }}
    //         variant="h4"
    //       >
    //         $1.71
    //       </span>{" "}
    //       <span style={{ float: "right" }}>#56988 </span> <br />
    //       <span style={{ float: "right" }}>Online Store</span>
    //     </Typography>
    //   </CardContent>
    //   <CardActions disableSpacing style={{ width: "100%" }}>
    //     <Typography
    //       variant="body2"
    //       align="left"
    //       style={{ float: "left!important" }}
    //     >
    //       Apr. 13, 2022 07:04 AM
    //     </Typography>
    //     <ExpandMore
    //       expand={expanded}
    //       onClick={handleExpandClick}
    //       aria-expanded={expanded}
    //       aria-label="show more"
    //     >
    //       <Typography variant="body2" style={{ textDecoration: "underline" }}>
    //         Transaction Details
    //       </Typography>
    //     </ExpandMore>
    //   </CardActions>
    //   <Collapse in={expanded} timeout="auto" unmountOnExit>
    //     <CardContent>
    //       <Typography paragraph align="left">
    //         1 x Blueberry Cinnamon
    //       </Typography>
    //     </CardContent>
    //   </Collapse>
    // </Card>,
  ];
}
