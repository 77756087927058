import React, { useEffect } from "react"; // Corrected import
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"; // Removed useRouteMatch since it's not used
import {
  Typography,
  Button,
  Paper,
  Grid,
  makeStyles, // Corrected placement
} from "@material-ui/core";
import Swal from "sweetalert2";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setLoading } from "../../../redux/publicReducer/actions";
import { setOrderSession } from "../../../redux/cartReducer/actions";
import { rewardsService } from "../../../services";

export const RewardsAsPayment = ({ setOpen }) => {
  const { selectedAddress, orderProcessingDate, orderProcessingType } =
    useSelector((state) => state.public);
  const { cartItems, tips, totalPriceItems, promoCode, rewardsBalance } =
    useSelector((state) => state.cart);

  const history = useHistory();
  const dispatch = useDispatch();

  const salesTax = parseFloat(totalPriceItems * 0.08);
  const couponAmount =
    promoCode && promoCode.match(/\bradio\b/gi)
      ? parseFloat(totalPriceItems * 0.2)
      : 0.0;

  const subTotal =
    promoCode && promoCode.match(/\bradio\b/gi)
      ? parseFloat(totalPriceItems) - parseFloat(totalPriceItems * 0.2)
      : totalPriceItems;

  const total = parseFloat(tips) + parseFloat(subTotal) + parseFloat(salesTax);

  let payload = {
    email: selectedAddress.email,
  };

  useEffect(() => {
    const checkBalance = rewardsService.checkRewardsBalance(payload);
    checkBalance.then((res) => {
      dispatch({ type: "SET_REWARDS_BALANCE", payload: res.total });
    });
  }, [dispatch, payload]); // Fixed dependencies

  const onPayment = () => {
    setOpen(false);
    dispatch(setLoading(true));
    if (totalPriceItems > rewardsBalance) {
      setOpen(false);
      history.push("/payment-wpay/");
    } else {
      let payments = [
        {
          "payment-type": "Rewards",
          amount: totalPriceItems,
          tip: tips,
        },
      ];

      const params = {
        cartItems: cartItems,
        selectedAddress: selectedAddress,
        total: totalPriceItems,
        tips: tips,
        promoCode: promoCode,
        pid: "",
        payments: payments,
        subTotal: subTotal,
        instructions: sessionStorage.getItem("instructions"),
        orderProcessingType: orderProcessingType ? "pickup" : "dine-in",
        orderProcessingDate: orderProcessingDate,
        orderId: 0,
      };

      const setOrders = rewardsService.setSessionOrders(params);
      setOrders.then((res) => {
        dispatch(setOrderSession(res.data));
        const handleRewardsPayment = rewardsService.handleRewardsPayment(
          res.data
        );
        handleRewardsPayment.then(() => {
          window.localStorage.clear();
          dispatch({ type: "SET_CART", payload: [] });
          dispatch(setLoading(false));
          Swal.fire(
            "Success",
            "Payment using rewards is successful.",
            "success"
          );
          setTimeout(function () {
            history.push("/menu");
          }, 2000);
        });
      });
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* Content omitted for brevity */}
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            style={{
              padding: "11px 0rem",
              borderRadius: "10px",
              backgroundColor: "#ff0000",
              boxShadow: "none",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#fff",
              letterSpacing: "1px",
            }}
            onClick={onPayment}
          >
            Proceed Payment
          </Button>
        </Paper>
      </div>
    </>
  );
};
