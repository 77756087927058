import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
export default function MobileForm(props) {
  return (
    <React.Fragment>
      <Container className="formBG" style={{ padding: "0" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          variant="outlined"
          padding="0"
        >
          <TextField
            onChange={(e) => props.getMobile(e.target.value)}
            value={props.mobile}
            margin="dense"
            required
            fullWidth
            id="mobile number"
            label="Mobile Number"
            name="Mobile Number"
            autoComplete="mobile number"
            autoFocus
          />
          <TextField
            onChange={(e) => props.getPassword(e.target.value)}
            value={props.password}
            margin="dense"
            required
            fullWidth
            id="password"
            type="password"
            label="Password"
            name="Password"
            autoComplete="password"
          />
          <TextField
            onChange={(e) => props.getCPassword(e.target.value)}
            value={props.cpassword}
            margin="dense"
            required
            fullWidth
            id="password2"
            type="password"
            label="Confirm Password"
            name="confirm password"
            autoComplete="confirm-password"
          />
        </Box>
      </Container>
    </React.Fragment>
  );
}
