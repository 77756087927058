import {
  SET_CART,
  SET_TOTAL_PRICE,
  SET_TIP,
  SET_SELECTED_MODE_PAYMENT,
  SET_PROMO_CODE,
  SET_ORDER_SESSION,
  SET_PAYMENT_OPTIONS,
  SET_REWARDS_BALANCE,
  SET_TOTAL_DISCOUNT,
  SET_GIFT_CARD_BALANCE,
  REMOVE_EXPIRED_ITEMS,
} from "./constants";
const initialState = {
  cartItems: [],
  totalPriceItems: 0,
  tips: 0,
  selectedPayment: null,
  promoCode: null,
  orderSession: null,
  paymentOptions: "",
  rewardsBalance: 0,
  totalDiscount: 0,
  giftCardBalance: 0,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CART: {
      return {
        ...state,
        cartItems: payload,
      };
    }

    case REMOVE_EXPIRED_ITEMS:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.expiration && item.expiration > Date.now()
        ),
      };

    case SET_TOTAL_PRICE: {
      return {
        ...state,
        totalPriceItems: payload,
      };
    }

    case SET_TIP: {
      return {
        ...state,
        tips: payload,
      };
    }

    case SET_SELECTED_MODE_PAYMENT: {
      return {
        ...state,
        selectedPayment: payload,
      };
    }

    case SET_PROMO_CODE: {
      return {
        ...state,
        promoCode: payload,
      };
    }

    case SET_TOTAL_DISCOUNT: {
      return {
        ...state,
        totalDiscount: payload,
      };
    }

    case SET_ORDER_SESSION: {
      return {
        ...state,
        orderSession: payload,
      };
    }

    case SET_PAYMENT_OPTIONS: {
      return {
        ...state,
        paymentOptions: payload,
      };
    }

    case SET_REWARDS_BALANCE: {
      return {
        ...state,
        rewardsBalance: payload,
      };
    }

    case SET_GIFT_CARD_BALANCE: {
      return {
        ...state,
        giftCardBalance: payload,
      };
    }

    default:
      return state;
  }
}
