import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

export default function CardMediaJoin() {
  return (
    <Card sx={{ maxWidth: 767 }}>
      <CardMedia
            component='video'
            src={"https://youtu.be/f4F9kUTmluY"}
            autoPlay
      />
    </Card>
  );
}